import Ajax from "Utils/ajax";
export const getAllStaff = (data) => Ajax.post('hr/getAllStaff',data);
export const getMainUsers = (data) => Ajax.post('hr/getMainUsers',data);
export const getAllHRGroup = (data) => Ajax.post('setting/getAllHRGroup',data);
export const getGrades = (data) => Ajax.post('setting/getAllGrade',data);
export const getDesignation = (data) => Ajax.post('setting/getAllDesignation',data);
export const getRating = (data) => Ajax.post('account/getRating',data);
export const getDetailByStaffId = (data) => Ajax.post('account/getDetailByStaffId',data);
export const generatePDF = (data) => Ajax.post('account/generatePDF',data);
export const getSalaryFixation = (data) => Ajax.post('account/getSalaryFixation',data);
export const generateSalaryFixation = (data) => Ajax.post('account/generateSalaryFixation',data);
export const staffApprasial = (data) => Ajax.post('account/staffApprasial',data);
export const getPastSalaryFixation = (data) => Ajax.post('account/getPastSalaryFixation',data);
export const getAppraisalDetails = (data) => Ajax.post('account/getAppraisalDetails',data);
export const getAllShiftType = (data) => Ajax.post('setting/getAllShiftType',data);
export const getSalaryFixationByAppraisalId = (data) => Ajax.post('account/getSalaryFixationByAppraisalId',data);

// export const getSalaryFixationDetails = (data) => Ajax.post('account/getSalaryFixationDetails',data);





