
<template>
    <div>
        <template v-if="row.Name">
            <FormButton size="small" type="text" @click="() => link()" :href="row.Name" v-if="row.Name" tag="a" left-icon="md-eye">View</FormButton>
        </template>
    </div>
</template>
<script>
    export default {
        name: "salaryFixationView",
        props: {
            row: {
                type: Object,
                required: true
            }
        },
        methods:{
            link() {
                this.$store.commit('setViweAppraisal',this.row);
                // window.open('ViewSalaryFixation',"b")
                this.$router.push({ path: "/ViewSalaryFixation" });
            }
        }
    }
</script>
