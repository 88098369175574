<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Appraisal History" slot="title" link="/helpContent/AppraisalHistory" />

    <ValidationObserver ref="validator">
      <div :class="$style.filter">
        <div :class="$style.filterItem">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="filtering.selectedStaff"
          />
        </div>

        <FormButton
          style="margin-top: 5px"
          type="primary"
          @click="search"
          :rounded="true"
        >
          <Icon type="ios-search" />
        </FormButton>
      </div>
    </ValidationObserver>
    <br />
    <DataTable
      :actions="true"
      :data="appraisalHistory"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      :pagination="pagination"
      @on-sort-change="sortChange"
    >
    </DataTable>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import { getAllStaff,getAppraisalDetails} from "../api";
import tableConfig from "./table";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      filtering: {
        selectedStaff: '',
      },
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
     showMessage: {
        isVisible: false,
        message: "",
      },
      staffList: [],
      appraisalHistory:[],
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getStaff();
  },
  methods: {
   async getStaff() {
    await  getAllStaff().then((res) => {
        this.staffList = res.data;
        this.filtering.selectedStaff =this.userData.user_id;
      });
      this.getDataByFilter();
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      setTimeout(() => {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            this.showLoader();
            const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;

            const filters = {};
            filters.staffId = this.filtering.selectedStaff;

            const data = {
              filterjson: {
                filter: [filters],
                sort: [{ key, order }],
                paging: [
                  {
                    startIndex: page,
                    pageSize: perPage,
                  },
                ],
              },
            };
            getAppraisalDetails(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }
        });
      }, 50);
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.appraisalHistory = response.data;
      this.pagination.total = response.NumberOfRecords;
    },
  },
};
</script>

<style lang="scss" module>
.popupRow {
  padding: 5px 0 15px;
}
.pagination {
  margin: 10px 0 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}

.paginationText {
  margin: 0 20px;
}

.paginationButton {
  cursor: pointer;
  width: 26px;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 200ms ease;
}
.paginationButton:hover {
  background: #57a3f3;
  color: #fff;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.filter {
  margin: -10px 0 10px;
}

.filterItem {
  display: inline-block;
  margin: 0 15px 15px 0;
}

.filterItemCheckbox {
  flex-basis: auto;
  align-self: flex-start;
  margin-top: 13px;
}
</style>
