import DocumentsActionsCell from '../components/salaryFixationView';

export default [
    // {
    //     type: 'selection',
    //     width: 38,
    //     align: 'center'
    // },
    { 
        title: 'Effective From',
        key: 'EffectiveFromDate',
        sortable: true,
    },
	{
        title: 'Effective To',
        key: 'EffectiveUpto',
		sortable: true,
        minWidth: 85,
    },
    {
        title: 'Designation',
        key: 'Designation',
        minWidth: 80,
    },
    {
        title: 'Grade',
        key: 'Grade',
        sortable: true,
    },
    {
        title: 'Appraised By',
        key: 'AppraisedBy',
        sortable: true,
    },
    {
        title: 'Appraised On',
        key: 'AppraisedOn',
        minWidth: 80,
    },
    {
        title: 'view',
        sortable: true,
        align: 'center',
        render: (h, params) => {
            return h('div', [
                h(DocumentsActionsCell, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },
]
